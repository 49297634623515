import React from "react";
import Footer from "./components/Footer";
import NavBar from "./components/NavBar";
import Hero from "./components/Hero";

function Portfolio() {
  return (
    <div>
      <NavBar />
      <Hero />
      <main id="portfolioMain">
        <h1>In House Interior Design & Furnishing</h1>
        <p>
          When we say that we are “ALL IN”, we mean just that. We cater to
          clients who are local as well as long distance, that desire to have
          their properties professionally Designed & Fully Furnished. Our design
          director has exquisite taste and will work with the Owners side by
          side, to completely design and furnish the home. Our team prepares and
          fully stocks the property with all items necessary for rental clients.
          During the initial conversations, the timeline and the budget are
          discussed to meet your deadlines, while ensuring profitability for
          owners and creating a 5-star experiences for their guests.
        </p>
        <p>
          Furnished or turnkey service is an option for investors who do not
          live close to their properties or do not have the time required to
          furnish the home themselves. Owners are invited to be involved as
          little or as much as they would like. We keep you completely informed
          on the progress of your home and maintain the agreed upon deadlines,
          to assure immediate rental occupancy for your investment property at
          the conclusion on the Design and Furnishing Process.
        </p>
        <section id="typeSections">
          <div id="portfolioCard">
            <h1>Terrace Condominiums</h1>
            <h2>2 bed 2 bath (1,100-1,300 sf)</h2>
            <div id="portfolioLinks">
              <a href="portfolio/terrace/arbor">
                <img
                  src="https://res.cloudinary.com/dqq0hq2d6/image/upload/v1725306907/05-1_jubdeb.jpg"
                  alt="house interior"
                  width={"350px"}
                />
                <span>Design I</span>
              </a>
              <a href="portfolio/terrace/birkdale">
                <img
                  src="https://res.cloudinary.com/dqq0hq2d6/image/upload/v1725306921/03-6_vawumk.jpg"
                  alt="house interior"
                  width={"350px"}
                />
                <span>Design II</span>
              </a>
              <a href="portfolio/terrace/carolina">
                <img
                  src="https://res.cloudinary.com/dqq0hq2d6/image/upload/v1725306934/015-1_g38qo8.jpg"
                  alt="house interior"
                  width={"350px"}
                />
                <span>Design III</span>
              </a>
            </div>
          </div>
          <div id="portfolioCard">
            <h1>Veranda Condominiums</h1>
            <h2>2 bed 2 bath plus den (1,400 - 1,700 sf)</h2>
            <div id="portfolioLinks">
              <a href="portfolio/veranda/brom">
                <img
                  src="https://res.cloudinary.com/dqq0hq2d6/image/upload/v1725309113/14_mhb1g8.jpg"
                  alt="house interior"
                  width={"350px"}
                />
                <span>Design I</span>
              </a>
              <a href="portfolio/veranda/dian">
                <img
                  src="https://res.cloudinary.com/dqq0hq2d6/image/upload/v1725307089/06-3_c5chpd.jpg"
                  alt="house interior"
                  width={"350px"}
                />
                <span>Design II</span>
              </a>
              <a href="portfolio/veranda/arab">
                <img
                  src="https://res.cloudinary.com/dqq0hq2d6/image/upload/v1725306974/06-3_wxmcnp.jpg"
                  alt="house interior"
                  width={"350px"}
                />
                <span>Design III</span>
              </a>
            </div>
          </div>
          <div id="portfolioCard">
            <h1>Coach Homes</h1>
            <h2>3 bed 2 bath (1,800 – 2,100 sf)</h2>
            <div id="portfolioLinks">
              <a href="portfolio/coach/arrow">
                <img
                  src="https://res.cloudinary.com/dqq0hq2d6/image/upload/v1725307167/014-2_rtic6n.jpg"
                  alt="house interior"
                  width={"350px"}
                />
                <span>Design I</span>
              </a>
            </div>
          </div>
          <div id="portfolioCard">
            <h1>Single Family Homes</h1>
            <h2>2,200 sf +</h2>
            <div id="portfolioLinks">
              <a href="portfolio/single/2000">
                <img
                  src="https://res.cloudinary.com/dqq0hq2d6/image/upload/v1725307193/12-3_ku4jqt.jpg"
                  alt="house interior"
                  width={"350px"}
                />
                <span>2,200 - 3,000 sf</span>
              </a>
              <a href="portfolio/single/3000">
                <img
                  src="https://res.cloudinary.com/dqq0hq2d6/image/upload/v1725307198/14-5_osftzf.jpg"
                  alt="house interior"
                  width={"350px"}
                />
                <span>3,000 + sf</span>
              </a>
            </div>
          </div>
        </section>
      </main>
      <Footer />
    </div>
  );
}

export default Portfolio;
