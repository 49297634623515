import NavBar from "./components/NavBar";
import Hero from "./components/Hero";
import Footer from "./components/Footer";

function About() {
  return (
    <div>
      <NavBar />
      <Hero />
      <section id="aboutSection">
        <h1>About</h1>
        <div id="aboutDiv">
          <div>
            <img
              id="aboutImg"
              src="/images/maja.jpg"
              alt="the owner of ALL IN standing under a archway"
              width="300px"
            />
          </div>
          <p>
            ALL IN Interior Design and Furnishing was founded in 2016 and is a
            southwest Florida interior design firm with furnishing services.
            Maya Mihajlovic, the owner, is a creative who believes in the power
            of beautiful interiors and make vacation homes/rental properties
            unique, with comfort and style. With a Master Degree and a
            well-trained eye for quality, furnishings and textiles, she
            continues to be recognized for impeccable designs and flawless
            project execution in time and on budget. Maya is best known for
            creating spaces that are comfortable, functional, friendly and
            beautiful. Each project speaks to her client’s own interests, tastes
            and style. She prides herself on her attention to detail and
            obsession with creating vacation homes and rental properties that
            stand out from the rest.
          </p>
        </div>
      </section>
      <Footer />
    </div>
  );
}

export default About;
