function Hero() {
  return (
    <section id="heroSection">
      <div id="hero">
        <div id="heroText">
          <h1>The simple, stress-free way to furnish your space.</h1>
          <p>
            Transform your space with ALL IN Design and Furnishing packages.
            End-to-end furnishing and interior styling services.
          </p>
          <button
            onClick={() =>
              (window.location.href =
                "mailto:allindesignandfurnishing@gmail.com?subject=Inquiry%20about%20furnishing%20services&body=Hi,%20I%27m%20interested%20in%20your%20furnishing%20packages.")
            }
          >
            GET IN TOUCH
          </button>
        </div>
      </div>
    </section>
  );
}

export default Hero;
