import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Footer from "./Footer";
import NavBar from "./NavBar";
import Hero from "./Hero";

function Unit() {
  const [pics, setPics] = useState([]);
  const { town, type, unit } = useParams();

  useEffect(() => {
    console.log(town, type, unit);

    fetch("/api/images", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ town, type, unit }),
    })
      .then((response) => response.json())
      .then((data) => {
        setPics(data);
      })
      .catch((error) => console.error("Error fetching data:", error));
  }, [town, type, unit]);

  console.log(pics);

  return (
    <div>
      <NavBar />
      <Hero />
      <main id="portfolioMain">
        <section id="typeSections">
          <div id="portfolioImages">
            {pics.map((pic, index) => (
              <img src={pic} alt="house interior" />
            ))}
          </div>
        </section>
      </main>
      <Footer />
    </div>
  );
}

export default Unit;
