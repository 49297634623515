import React, { useState, useEffect } from "react";
import Footer from "./components/Footer";
import NavBar from "./components/NavBar";
import Hero from "./components/Hero";
import ReviewCard from "./components/ReviewCard";

function Admin() {
  const [reviews, setReviews] = useState([]);
  const [key, setKey] = useState("");
  const [accessGranted, setAccessGranted] = useState(false);
  const [error, setError] = useState("");

  useEffect(() => {
    if (accessGranted) {
      fetch("/api/unapproved/reviews")
        .then((response) => response.json())
        .then((data) => setReviews(data))
        .catch((error) => console.error("Error fetching reviews:", error));

      setReviews([
        {
          approved: false,
          name: "test",
          review: 5,
          reviewText: "awesome",
          __v: 0,
          _id: "1",
        },
        {
          approved: false,
          name: "test",
          review: 5,
          reviewText: "awesome",
          __v: 0,
          _id: "2",
        },
        {
          approved: false,
          name: "test",
          review: 5,
          reviewText: "awesome",
          __v: 0,
          _id: "3",
        },
        {
          approved: false,
          name: "test",
          review: 5,
          reviewText: "awesome",
          __v: 0,
          _id: "4",
        },
        {
          approved: false,
          name: "test",
          review: 5,
          reviewText: "awesome",
          __v: 0,
          _id: "5",
        },
      ]);
    }
  }, [accessGranted]);

  const handleKeySubmit = (event) => {
    event.preventDefault();
    fetch("/api/adminkey", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ key }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.success) {
          setAccessGranted(true);
          setError("");
        } else {
          setError("Incorrect key. Please try again.");
        }
      })
      .catch((error) =>
        setError("Error validating key. Please try again later.")
      );
  };

  const handleApprove = (id) => {
    fetch("/api/unapproved/reviews", {
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ id }),
    })
      .then((response) => response.json())
      .then((data) => setReviews(data))
      .catch((error) => console.error("Error approve reviews:", error));
  };

  const handleDelete = (id) => {
    fetch("/api/unapproved/reviews", {
      method: "DELETE",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ id }),
    })
      .then((response) => response.json())
      .then((data) => setReviews(data))
      .catch((error) => console.error("Error deleting reviews:", error));
  };

  return (
    <div>
      <NavBar />
      <Hero />
      {!accessGranted ? (
        <div className="keyContainer">
          <section id="keySection">
            <h1>Enter Admin Key</h1>
            <form onSubmit={handleKeySubmit}>
              <input
                type="password"
                value={key}
                onChange={(e) => setKey(e.target.value)}
                placeholder="Enter Admin Key"
                required
              />
              <button type="submit">Submit</button>
            </form>
            {error && <p style={{ color: "red" }}>{error}</p>}
          </section>
        </div>
      ) : (
        <section id="adminSection">
          <h1>Admin</h1>
          <div id="reviewsBox">
            <div id="reviews">
              {reviews.map((reviewObj, index) => (
                <div key={reviewObj._id} id={reviewObj._id}>
                  <ReviewCard key={index} review={reviewObj} />
                  <button
                    style={{ marginRight: "15px" }}
                    onClick={() => handleApprove(reviewObj._id)}
                  >
                    Approve✅
                  </button>
                  <button onClick={() => handleDelete(reviewObj._id)}>
                    Delete⛔
                  </button>
                </div>
              ))}
            </div>
          </div>
        </section>
      )}
      <Footer />
    </div>
  );
}

export default Admin;
