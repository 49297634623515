import React, { useState } from "react";

function ReviewForm() {
  const [name, setName] = useState("");
  const [review, setReview] = useState("");
  const [stars, setStars] = useState(1);
  const [submit, setSumbit] = useState(false);

  const handleSubmit = (event) => {
    setSumbit(true);
    event.preventDefault();

    const reviewData = {
      name,
      review,
      stars,
    };

    console.log(reviewData);

    fetch("/api/upload/review", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(reviewData),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log("Review successfully posted:", data);
        setSumbit(true);
      })
      .catch((error) => console.error("Error posting review:", error));
  };

  return (
    <div>
      <h1>Leave a Review</h1>
      {submit && <p style={{ color: "green", margin: "0px" }}>Success!</p>}
      <form onSubmit={handleSubmit}>
        <div>
          <label htmlFor="name">Name:</label>
          <input
            type="text"
            id="name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            required
          />
        </div>
        <div>
          <label htmlFor="review">Review:</label>
          <textarea
            id="review"
            value={review}
            onChange={(e) => setReview(e.target.value)}
            maxLength="150"
            required
          />
          <small>{150 - review.length} characters remaining</small>
        </div>
        <div>
          <label htmlFor="stars">⭐Stars:</label>
          <select
            id="stars"
            value={stars}
            onChange={(e) => setStars(e.target.value)}
            required
          >
            {[1, 2, 3, 4, 5].map((star) => (
              <option key={star} value={star}>
                {star}
              </option>
            ))}
          </select>
        </div>
        <button type="submit" style={{ padding: "10px 40px" }}>
          Submit
        </button>
      </form>
    </div>
  );
}

export default ReviewForm;
