import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Footer from "./Footer";
import NavBar from "./NavBar";
import Hero from "./Hero";

function Town() {
  const [units, setUnits] = useState([]);
  const { town, type } = useParams();

  useEffect(() => {
    console.log(town, type);

    fetch("/api/files", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ town, type }),
    })
      .then((response) => response.json())
      .then((data) => {
        setUnits(data);
      })
      .catch((error) => console.error("Error fetching data:", error));
  }, [town, type]);

  return (
    <div>
      <NavBar />
      <Hero />
      <main id="portfolioMain">
        <section id="typeSections">
          <div id="portfolioCard">
            <div id="portfolioLinks">
              {units.map((unit, index) => (
                <a key={index} href={`${town}/${unit.folder}`}>
                  <img src={unit.imageUrl} alt="house interior" width="350px" />
                  <span>
                    {unit.folder.charAt(0).toUpperCase() + unit.folder.slice(1)}
                  </span>
                </a>
              ))}
            </div>
          </div>
        </section>
      </main>
      <Footer />
    </div>
  );
}

export default Town;
