import React from "react";
import { IoMdMail } from "react-icons/io";

function NavBar() {
  return (
    <header>
      <div className="nav-container">
        <a href="/" id="logo">
          <img src="/images/logo-transparent-svg.svg" alt="Logo" width="200" />
        </a>
        <div id="rightSide">
          <div id="socials">
            <a href="mailto:allindesignandfurnishing@gmail.com">
              <IoMdMail />
            </a>
          </div>
          <div id="navTags">
            <a href="/">HOME</a>
            <a href="/about">ABOUT US</a>
            <a href="/portfolio">SEE OUR WORK</a>
          </div>
        </div>
      </div>
    </header>
  );
}

export default NavBar;
