import React, { useState, useEffect } from "react";

function ReviewCard({ review }) {
  const [stars, setStars] = useState("");

  useEffect(() => {
    let starString = "";
    for (let i = 0; i < review.review; i++) {
      starString += "⭐";
    }
    setStars(starString);
  }, [review.review]);

  return (
    <div className="reviewCard">
      <h1>{review.name}</h1>
      <h2 className="stars">{stars}</h2>
      <p>{review.reviewText}</p>
    </div>
  );
}

export default ReviewCard;
